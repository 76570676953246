<!-- 印章管理页面 3.0程永雪-->
<template>
  <section style="margin: 0 24px" ref="exportRef">
    <!-- 筛选区块始 -->
    <section class="form-style">
      <a-form layout="inline">
        <a-form-item label="印章名称：">
          <a-input
            class="form-input"
            allowClear
            :maxlength="20"
            v-model:value="screen.searchName"
            placeholder="请输入"
            ref="inputbox"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="印章保管员：">
          <a-input
            class="form-input"
            allowClear
            :maxlength="20"
            v-model:value="screen.trusteeName"
            placeholder="请输入"
            ref="inputbox"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="印章状态：">
          <a-select class="form-select" v-model:value="screen.checkStatus" placeholder="请选择">
            <a-select-option value="1">正常</a-select-option>
            <a-select-option value="2">停用</a-select-option>
            <a-select-option value="3">作废</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="form-btns">
          <a-button @click="reset">重置</a-button>
          <a-button @click="searchList" class="form-btn">查询</a-button>
        </a-form-item>
      </a-form>
    </section>
    <!-- 筛选区块终 -->
    <!-- 列表区域始 -->
    <section class="table-style">
      <div class="table-head">
        <div class="left-info">
          <span class="title">印章列表</span>
          <!-- <div class="table-bottom" v-if="selectedRowKeys.length">
            <a-button class="batch-button" @click="goSetting(1)" v-btn="'PC_SEAL_UPDATE'">批量设置印章保管员</a-button>
            <a-button class="batch-button" @click="goSetting(3)" v-btn="'PC_SEAL_UPDATE'">批量设置特权用印人</a-button>
          </div> -->
        </div>
        <div class="operation-btn">
          <a-button @click.stop="exportSeal()" v-btn="'PC_SEAL_UPDATE'">
            <i class="iconfont icon-daochu head-export" />导出
          </a-button>
          <div class="export-box" v-if="showExport">
            <p :class="[selectedRowKeys.length ? 'item' : 'no-select']" @click="exportSealTable(2)">导出选中数据</p>
            <p class="item" @click="exportSealTable(1)">导出全部数据</p>
          </div>
          <!-- <a-button class="add-btn" v-btn="'PC_SEAL_ADD'" @click.prevent="addSeals()">
            <PlusOutlined class="add-icon" />添加印章
          </a-button> -->
        </div>
      </div>
      <a-table
        :columns="managementColumns"
        :dataSource="dataSource"
        :pagination="selectedRowKeys.length ? false : pagination"
        @change="handleTableChange"
        :loading="tableLoading"
        rowKey="sealId"
        ref="tableRef"
        :scroll="{ x: 400 }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      >
        <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
        <template #trusteeNames="{ text }">
          <a-tooltip v-if="text?.length > 12" placement="bottomLeft" overlayClassName="seal-trustee-overlay">
            <template #title>
              <span>{{ text }}</span>
            </template>
            <div class="seal-name-box">{{ text }}</div>
          </a-tooltip>
          <span v-else :title="text">{{ text ?? '-' }}</span>
        </template>
        <!-- 印章名称 -->
        <template #sealName="{ text }">
          <a-tooltip placement="bottomLeft" v-if="text?.length > 21" overlayClassName="lang-name-pop">
            <template #title>
              <span>{{ text }}</span>
            </template>
            <div class="seal-name-box">{{ text }}</div>
          </a-tooltip>
          <span v-else>{{ text ?? '-' }}</span>
        </template>
        <!-- 印章类型 -->
        <template #sealType="{ record }">
          <span :title="record.sealTypeName">{{ record.sealTypeName }}</span>
        </template>
        <template #dippingType="{ record }">
          <span>{{ record.type == 1 ? '蘸墨印章' : '非蘸墨印章' }}</span>
        </template>
        <!-- 特权用印人 -->
        <template #sealRelNames="{ record }">
          <span :title="record.sealRelNames">{{ record.sealRelNames ?? '-' }}</span>
        </template>
        <!-- 印章状态 -->
        <template #status="{ record }">
          <div class="device-state">
            <div
              class="dotted"
              :style="{ background: record.status == 1 ? '#52C41A' : record.status == 2 ? '#CCCCCC' : '#C3161C' }"
            ></div>
            <div :style="{ 'margin-right': record.isLock === 1 ? 0 : '12px' }">
              <span>{{ record.status == 1 ? '正常' : record.status == 2 ? '停用' : '作废' }} </span>
            </div>
          </div>
        </template>
        <!-- 蘸墨类型 -->
        <template #address="{ text }">
          <span :title="text">{{ text ?? '-' }}</span>
        </template>
        <template #number="{ text, record }">
          <span v-if="record.status === 0">未装在设备上</span>
          <span v-else :title="text"> {{ text ? text : '-' }}</span>
        </template>
        <template #deviceState="{ text, record }">
          <section class="device-state" v-if="record.sealType === 1 && record.model != 'YDA-J'">
            <div class="dotted" :style="{ background: text ? '#52C41A' : '#CCCCCC' }"></div>
            <div>
              <span>{{ text ? '在线' : '离线' }} </span>
            </div>
            <template v-if="record.isLock === 1">
              <span style="font-size:12px">（已锁定）</span>
            </template>
            <template v-if="record.railType !== 1">
              <img
                :title="record.railType === 2 ? '在围栏内' : '不在围栏内'"
                :src="
                  record.railType === 2
                    ? require('@/assets/images/in-fence.png')
                    : require('@/assets/images/out-fence.png')
                "
                class="fence-img"
              />
            </template>
          </section>
          <div v-else>-</div>
        </template>
        <!-- 操作 -->
        <!--status 1.启用2.停用3.作废 -->
        <template #action="{ record }">
          <a
            v-if="record.status == 3"
            @click="openCheckModal(record)"
            style="margin-right: 16px"
            class="table-text-color"
            >查看</a
          >
          <!-- <a
            v-if="record.status != 3"
            @click="addSeals(record.sealId)"
            v-btn="'PC_SEAL_UPDATE'"
            style="margin-right: 16px"
            class="table-text-color"
            >修改</a
          > -->
          <template v-if="isShowMore">
            <!-- <a-dropdown v-if="record.status != 3" class="dropdown-more" placement="bottomCenter">
              <a @click.prevent
                >更多
                <DownOutlined style="margin-left: 4px" />
              </a>
              <template #overlay>
                <a-menu class="dropdown-menu">
                  <a-menu-item v-if="record.status == 2" key="1" class="dropdown-item">
                    <a @click="deactivate(record.sealId, 1)" v-btn="'PC_SEAL_UPDATE'">启用</a>
                  </a-menu-item>
                  <a-menu-item v-if="record.status == 1" key="2" class="dropdown-item">
                    <a @click="deactivate(record.sealId, 2)" v-btn="'PC_SEAL_UPDATE'">停用</a>
                  </a-menu-item> -->
                  <!-- <a-menu-item key="3" class="dropdown-item"> -->
                    <a @click="openCheckModal(record)" v-btn="'PC_SEAL_VIEW'">查看</a>
                  <!-- </a-menu-item> -->
                  <!-- <a-menu-item key="4" v-if="record.sealType === 1" class="dropdown-item">
                    <a @click="deactivate(record.sealId, 3, record.number === null)" v-btn="'PC_SEAL_UPDATE'">作废</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown> -->
          </template>
        </template>
        <!-- 操作终 -->
      </a-table>
      <!-- 批量操作模块 -->
      <!-- <div class="table-bottom" v-if="selectedRowKeys.length">
        <a-button class="batch-button" @click="goSetting(1)" v-btn="'PC_SEAL_UPDATE'">设置印章保管员</a-button>
        <a-button class="batch-button" @click="goSetting(3)" v-btn="'PC_SEAL_UPDATE'">设置特权用印人</a-button> -->
      <!-- 设置数据查看人 角色权限2.0删除 -->
      <!-- <a-button class="batch-button" @click="goSetting(2)">设置数据查看人</a-button> -->
      <!-- </div> -->
    </section>
    <!-- 列表区域终 -->
    <!-- 查看详情弹框-->
    <cModal :visible="cmvisible" :current="current" @close="handleModalClose" is />
  </section>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs, computed, onUnmounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { managementColumns } from './columns'
import cModal from './components/checkModal'
import { getSealList, permissionCheck } from '@/apis/sealManage.js'
import { getDepartmentId } from '@/apis/businessManage'
import { Modal } from 'ant-design-vue'
import { cmsNotice, setTablePageFooterStyle } from '@/utils/utils'
import { DownOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { sealCancel, exportSeals } from '@/apis/sealManagement.js'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    cModal,
    PlusOutlined,
    DownOutlined
  },
  setup() {
    const store = useStore()
    // new筛选数据
    const screen = reactive({
      // 印章名称
      searchName: undefined,
      // 保管员
      trusteeName: undefined,
      // 印章状态（1启用2停用3作废）
      status: [1, 2, 3],
      checkStatus: undefined
    })
    const selectedRowKeys = computed(() => store.state.sealManage.checkedRows) //选中的行
    const pagination = computed(() => store.state.sealManage.paginationParams)
    const router = useRouter()
    const route = useRoute()
    const inputbox = ref(null)
    const tableRef = ref()
    const state = reactive({
      searchVal: undefined,
      managementColumns,
      dataSource: [],
      cmvisible: false,
      current: undefined,
      tableLoading: true,
      currentNum: 1,
      showExport: false
    })
    let exportParams = null
    const exportRef = ref()
    const isShowMore = computed(() => {
      let view = router.currentRoute.value.meta.permission.includes('PC_SEAL_VIEW')
      let edit = router.currentRoute.value.meta.permission.includes('PC_SEAL_UPDATE')
      return view || edit
    })
    const closeEx = e => {
      // console.log('ref', exportRef, e);
      if (document.querySelector('#app').contains(e.target)) {
        state.showExport = false
      }
    }
    // 停用、启用、作废
    const deactivate = async (
      sealId,
      status,
      binding = true
      // 是否已经绑定设备
    ) => {
      let _title = ''
      let _text = ''
      if (status == 1) {
        _title = '确认启用'
        _text = '是否启用该印章？'
      }
      if (status == 2) {
        // debugger
        const { sealStatus } = state.dataSource.find(item => item.sealId === sealId)
        if (sealStatus !== 1) {
          const params = {
            id: sealId,
            status: status
          }
          const res = await sealCancel(params)
          if (res.success) {
            cmsNotice('success', '操作成功')
          }
          getList()
          return
        }
        _title = '确认停用'
        _text = '此章处于待用印状态，是否停用？'
      }
      if (status == 3) {
        _title = '确认作废'
        if (!binding) {
          _text = '该印章已绑定设备，请前往APP卸载印章后重试！'
        } else {
          _text = '作废后无法恢复，是否作废？'
        }
      }
      Modal.confirm({
        title: _title,
        content: _text,
        centered: true,
        onOk: async () => {
          if (!binding) {
            return
          }
          const params = {
            id: sealId,
            status: status
          }
          const res = await sealCancel(params)
          if (res.success) {
            cmsNotice('success', '操作成功')
          }
          getList()
        }
      })
    }

    // 请求印章列表
    const getList = async () => {
      state.tableLoading = true
      const params = {
        // 印章状态
        status: screen.checkStatus,
        // 印章名称
        searchName: screen.searchName,
        // 印章保管员
        trusteeName: screen.trusteeName,
        pageIndex: store.state.sealManage.paginationParams.current,
        pageSize: store.state.sealManage.paginationParams.pageSize
      }
      const res = await getSealList(params)
      if (res.success) {
        console.log('印章管理列表', res.data)
        state.dataSource = res.data
        store.state.sealManage.paginationParams.total = res.totalItem
        store.state.sealManage.paginationParams.index = res.pageIndex
        localStorage.setItem('paginationParams', JSON.stringify(store.state.sealManage.paginationParams))
        state.tableLoading = false
        setTablePageFooterStyle(tableRef)
      }
    }

    // 列表筛选查询
    const searchList = () => {
      // 清空前后空格
      if (state.searchVal) {
        state.searchVal = state.searchVal.replace(/\s*/g, '')
      }
      // store.state.sealManage.paginationParams.current = state.searchVal ? 1 : state.currentNum
      store.state.sealManage.paginationParams.current = 1
      localStorage.setItem('paginationParams', JSON.stringify(store.state.sealManage.paginationParams))

      exportParams = {
        searchName: screen.searchName,
        trusteeName: screen.trusteeName, // 保管员
        status: screen.checkStatus
      }
      getList()
    }

    // 分页
    const handleTableChange = ({ current, pageSize }) => {
      state.dataSource = []
      store.state.sealManage.paginationParams.current = current
      state.currentNum = current
      store.state.sealManage.paginationParams.pageSize = pageSize
      localStorage.setItem('paginationParams', JSON.stringify(store.state.sealManage.paginationParams))

      getList()
    }

    // 打开查看弹框
    const openCheckModal = current => {
      state.cmvisible = true
      state.current = current
    }

    // 关闭查看详情弹框
    const handleModalClose = () => {
      state.cmvisible = false
    }

    const dgOrganiz = arr => {
      const fn = list => {
        return list.map(item => {
          if (item.departmentDTOList.length) {
            fn(item.departmentDTOList)
          } else {
            delete item.departmentDTOList
          }
          return item
        })
      }
      return fn(arr)
    }
    const isSuper = ref()
    // 获取
    const getIds = async () => {
      isSuper.value = await getDepartmentId()
    }

    // 初始
    onMounted(() => {
      console.log('pagination', pagination.value)
      if (!JSON.parse(localStorage.getItem('checkedRows'))) {
        localStorage.setItem('checkedRows', JSON.stringify(store.state.sealManage.checkedRows))
      } else {
        store.state.sealManage.checkedRows = JSON.parse(localStorage.getItem('checkedRows'))
      }
      if (!JSON.parse(localStorage.getItem('paginationParams'))) {
        localStorage.setItem('paginationParams', JSON.stringify(store.state.sealManage.paginationParams))
      } else {
        store.state.sealManage.paginationParams = JSON.parse(localStorage.getItem('paginationParams'))
      }

      getList()
      getIds()
      document.addEventListener('click', closeEx)
    })
    onUnmounted(() => {
      console.log('卸载了')
      document.removeEventListener('click', closeEx)
    })

    // 修改或添加印章
    const addSeals = sealId => {
      console.log('印章id', sealId)
      let _path = ''
      // 这两个路径指向同一页面
      if (sealId != undefined) {
        // 添加印章
        _path = '/sealManage/adds'
      } else {
        // 修改印章
        _path = '/sealManage/add'
      }
      router.push({
        path: _path,
        query: {
          sealId: sealId
        }
      })
    }
    const exportSeal = () => {
      state.showExport = !state.showExport
    }
    const exportSealTable = async type => {
      //导出列表
      if (type === 2 && !selectedRowKeys.value.length) return
      const res = await permissionCheck({
        code: 'PC_SEAL_UPDATE'
      })
      if (!res.data) return cmsNotice('warning', '您无权限导出')
      if (!state.dataSource.length) return cmsNotice('warning', '无印章数据，无法导出')
      window.location = exportSeals({
        deriveType: type, //   1全部导出  2选中导出
        // searchName: screen.searchName,
        // trusteeName: screen.searchName,// 保管员
        // status: screen.checkStatus,
        ...exportParams,
        sealIds: type === 1 ? '' : selectedRowKeys.value,
        sessionId: localStorage.getItem('yda-qywx-sessionId')
      })
      setTimeout(() => {
        cmsNotice('success', '正在为您导出，请耐心等待...')
      }, 200)
    }

    // 搜索重置
    const reset = () => {
      screen.checkStatus = undefined
      screen.searchName = ''
      screen.trusteeName = ''
      exportParams = null
      getList()
    }
    //列表多选
    const onSelectChange = selectedRowKeys => {
      store.state.sealManage.checkedRows = []
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      store.state.sealManage.checkedRows = selectedRowKeys
      localStorage.setItem('checkedRows', JSON.stringify(store.state.sealManage.checkedRows))
    }
    //跳转至批量设置页
    const goSetting = index => {
      //TODO：权限拦截接口请求

      //设置数据查看人 角色权限2.0删除
      let params = {
        code: 'PC_SEAL_UPDATE'
      }
      permissionCheck(params)
        .then(res => {
          if (res.data) {
            //有权限
            let path = ''
            switch (index) {
              case 1: //设置印章保管员
                path = '/sealManage/setSealManage'
                break
              case 2: //设置数据查看人
                path = '/sealManage/setViewer'
                break
              case 3: //设置特权用印人
                path = '/sealManage/setPrivilegedPerson'
                break
            }
            return router.push({
              path,
              query: {
                type: index //设置类型
              }
            })
          }
          cmsNotice('error', '无操作权限')
        })
        .catch(err => {
          console.log(err)
        })
    }

    return {
      ...toRefs(state),
      openCheckModal,
      searchList,
      exportRef,
      handleTableChange,
      tableRef,
      inputbox,
      isSuper,
      handleModalClose,
      addSeals,
      exportSeal,
      exportSealTable,
      screen,
      reset,
      deactivate,
      onSelectChange,
      goSetting,
      selectedRowKeys,
      pagination,
      isShowMore
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(.ant-select) {
  .ant-select-selector {
    height: 36px;
    border-radius: 4px;
    line-height: 36px;
  }

  .ant-select-selection-placeholder {
    line-height: 36px;
  }

  .ant-select-selection-item {
    line-height: 36px;
  }
}

.seal-name-box {
  max-width: 21em;

  /* 设置文本为多行显示 */
  white-space: nowrap;

  /* 控制文本溢出时的显示 */
  overflow: hidden;
  text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 2; /* 控制显示的行数 */
  // -webkit-box-orient: vertical;
}
.device-state {
  display: flex;
  align-items: center;
  // justify-content: center;

  .dotted {
    width: 6px;
    height: 6px;
    margin-right: 7px;
    display: inline-block;
    border-radius: 50%;
  }

  .fence-img {
    width: 16px;
    height: 13px;
    margin: -2px 0 0 12px;
  }
}

.dropdown-more {
  color: #1890ff;
  font-size: 14px;
}

.dropdown-menu {
  width: 95px;
  border-radius: 4px;

  :deep(.ant-dropdown-menu-item) {
    text-align: center;
    font-size: 12px;
    color: #333333;

    &:hover {
      background: rgba($color: #1890ff, $alpha: 0.05);
    }
  }
}
.table-style {
  position: relative;
}
.left-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  .table-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    .batch-button {
      width: 138px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #c3161c;
      font-size: 14px;
      font-weight: 400;
      color: #c3161c;
      line-height: 22px;
      padding: 0;
      &:last-child {
        margin-left: 8px;
      }
    }
  }
}

.operation-btn {
  position: relative;
  .head-export {
    font-size: 14px;
    margin-right: 8px;
  }
  .export-box {
    position: absolute;
    top: 38px;
    left: 17px;
    z-index: 99;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    p {
      padding: 6px 10px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 20px;
    }
    .no-select {
      color: rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
    }
    .item:hover {
      color: #fff;
      background: #c3161c;
      cursor: pointer;
    }
  }
}
</style>
